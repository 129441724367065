export enum LogisticRoutes {
  Logistics = "/logistic/logistics",
  Trucks = "/logistic/trucks",
  PendingOrders = "/logistic/pendingorders",
  DispatchRoutes = "/logistic/dispatchRoutes",
  Reports = "/logistic/reports",
}

export enum Routes {
  Home = "/",
  Login = "/login",
}
