import { ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Drawer } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { LogisticRoutes, Routes } from "../config/Routes";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";

export const Menu: React.FC = () => {
    const history = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const route = history.pathname;

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return store.dispatch({ type: AuthActions.DelSession, payload: null });
    };

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className="flex justify-between">
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setIsNavOpen(true)}>
                        <Icon path={mdiMenu} size={1} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer anchor="left" PaperProps={{ sx: { backgroundColor: "#ED6E11", color: "white" } }} open={isNavOpen} onClose={() => setIsNavOpen(false)}>

                <div className="flex flex-col space-y-4 p-4">
                    <Link to={Routes.Home}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === Routes.Home ? "text-archiLightBlue" : ""}`}>Inicio</div>
                    </Link>
                    <Link to={LogisticRoutes.Logistics}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === LogisticRoutes.Logistics ? "text-archiLightBlue" : ""}`}>Logística</div>
                    </Link>
                    <Link to={LogisticRoutes.Trucks}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === LogisticRoutes.Trucks ? "text-archiLightBlue" : ""}`}>Camiones</div>
                    </Link>
                    <Link to={LogisticRoutes.PendingOrders}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === LogisticRoutes.PendingOrders ? "text-archiLightBlue" : ""}`}>Ordenes Pendientes</div>
                    </Link>
                    <Link to={LogisticRoutes.DispatchRoutes}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === LogisticRoutes.DispatchRoutes ? "text-archiLightBlue" : ""}`}>Rutas de Despacho</div>
                    </Link>
                    <Link to={LogisticRoutes.Reports}>
                        <div onClick={() => setIsNavOpen(false)} className={`${route === LogisticRoutes.Reports ? "text-archiLightBlue" : ""}`}>Reportería</div>
                    </Link>

                    <div onClick={handleLogout} style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemText>Cerrar sesión</ListItemText>
                        <ListItemIcon sx={{ color: "inherit", minWidth: '0', marginRight: '8px' }}>
                            <Icon className="w-7" path={mdiLogoutVariant}></Icon>
                        </ListItemIcon>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
