import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, CircularProgress, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import {
    DataGridPro,
    GridColDef,
    esES,
} from "@mui/x-data-grid-pro";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { fetchConsolidatedInvoicesWithDates } from '../../../services/invoiceService';
import { useAppSelector } from '../../../hooks/storeHooks';
import { fetchTrucks } from '../../../services/truckService';
import handleGenerateExcelOrdersWithInvoices from '../../../utils/handleGenerateExcelOrdersWithInvoices';

type DateKeys = 'orderId' | 'sentToERPDate' | 'pickerAssignDate' | 'pickerFinishDate' | 'date' | 'assignedTruckDate' | 'changedStatusDate';

const ReportsView: React.FC = () => {
    const [invoices, setInvoices] = useState<InvoiceWithDates[]>([]);
    const [loading, setLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedState1, setSelectedState1] = useState<DateKeys>('orderId');
    const [selectedState2, setSelectedState2] = useState<DateKeys>('sentToERPDate');
    const trucks = useAppSelector((state) => state.truckReducer.trucks);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await fetchTrucks();
            const data = await fetchConsolidatedInvoicesWithDates();
            setInvoices(data);
            setLoading(false);
        };
        fetchData();
    }, []);

    const getTruckUsername = (truckId: string) => {
        const truck = trucks.find((truck) => truck._id === truckId);
        return truck ? truck.username : 'Unknown';
    };

    const convertOrderIdToDate = (orderId: string) => {
        if (!orderId) return 'N/A';
        const date = new Date(parseInt(orderId, 10));
        return isNaN(date.getTime()) ? 'N/A' : date.toLocaleString();
    };

    const convertToLocaleString = (dateString: string) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'N/A' : date.toLocaleString();
    };

    const columns: GridColDef[] = [
        { field: 'orderId', headerName: 'Order ID', width: 150 },
        { field: 'laudusOrderId', headerName: 'Laudus Order ID', width: 150 },
        { field: 'invoiceLaudusId', headerName: 'Invoice ID', width: 150 },
        { field: 'docNumber', headerName: 'Numero Documento', width: 150 },
        { field: 'name', headerName: 'Nombre', width: 200 },
        { field: 'rut', headerName: 'RUT', width: 150 },
        { field: 'netAmount', headerName: 'Monto Neto', width: 150 },
        { field: 'status', headerName: 'Estado', width: 150 },
        { field: 'sellerName', headerName: 'Nombre Vendedor', width: 150 },
        { field: 'truck', headerName: 'Camion', width: 150, valueGetter: (params) => getTruckUsername(params.row.truck) },
        { field: 'commune', headerName: 'Comuna', width: 150 },
        { field: 'orderDate', headerName: 'Fecha Ingreso Pedido', width: 200, valueGetter: (params) => convertOrderIdToDate(params.row.orderId) },
        { field: 'sentToERPDate', headerName: 'Fecha Envio a ERP', width: 200, valueGetter: (params) => convertToLocaleString(params.row.sentToERPDate) },
        { field: 'pickerAssignDate', headerName: 'Fecha Asignacion Picker', width: 200, valueGetter: (params) => convertToLocaleString(params.row.pickerAssignDate) },
        { field: 'pickerFinishDate', headerName: 'Fecha Termino Picker', width: 200, valueGetter: (params) => convertToLocaleString(params.row.pickerFinishDate) },
        { field: 'date', headerName: 'Fecha Factura', width: 200, valueGetter: (params) => convertToLocaleString(params.row.date) },
        { field: 'assignedTruckDate', headerName: 'Fecha Asignacion Camion', width: 200, valueGetter: (params) => convertToLocaleString(params.row.assignedTruckDate) },
        { field: 'changedStatusDate', headerName: 'Fecha Cambio de Estado', width: 200, valueGetter: (params) => convertToLocaleString(params.row.changedStatusDate) },
    ];

    const statusData = invoices.reduce((acc, invoice) => {
        const status = invoice.status;
        if (!acc[status]) {
            acc[status] = 0;
        }
        acc[status]++;
        return acc;
    }, {} as Record<string, number>);

    const pieData = Object.keys(statusData).map((key) => ({
        name: key,
        value: statusData[key],
    }));

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000'];

    const calculateAverageTime = (startDateKey: DateKeys, endDateKey: DateKeys) => {
        const validIntervals = invoices
            .map((invoice) => {
                const startDateValue = invoice[startDateKey];
                const endDateValue = invoice[endDateKey];
                if (startDateValue && endDateValue) {
                    const startDate = new Date(startDateValue);
                    const endDate = new Date(endDateValue);
                    if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                        return endDate.getTime() - startDate.getTime();
                    }
                }
                return null;
            })
            .filter((interval): interval is number => interval !== null);

        if (validIntervals.length === 0) return 'N/A';

        const averageTime = validIntervals.reduce((acc, interval) => acc + interval, 0) / validIntervals.length;
        return `${(averageTime / (1000 * 60 * 60 * 24)).toFixed(2)} días`;
    };

    const stateOptions = [
        { value: 'orderId', label: 'Fecha Ingreso Pedido' },
        { value: 'sentToERPDate', label: 'Fecha Envio a ERP' },
        { value: 'pickerAssignDate', label: 'Fecha Asignacion Picker' },
        { value: 'pickerFinishDate', label: 'Fecha Termino Picker' },
        { value: 'date', label: 'Fecha Factura' },
        { value: 'assignedTruckDate', label: 'Fecha Asignacion Camion' },
        { value: 'changedStatusDate', label: 'Fecha Cambio de Estado' },
    ];

    return (
        <Box>
            <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
                <Tab label="Vista Consolidada" />
                <Tab label="Grafico de Estados" />
                <Tab label="Tiempos Promedio" />
            </Tabs>
            {tabIndex === 0 && (
                <Box p={2}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <div className='my-2'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleGenerateExcelOrdersWithInvoices(invoices)}
                                    style={{ marginBottom: '10px' }}
                                >
                                    Descargar Excel
                                </Button>
                            </div>
                            <DataGridPro
                                rows={invoices}
                                columns={columns}
                                pagination
                                pageSize={200}
                                autoHeight
                                getRowId={(row) => row._id}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </>
                    )}
                </Box>
            )}
            {tabIndex === 1 && (
                <Box p={2}>
                    <PieChart width={800} height={400}>
                        <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </Box>
            )}
            {tabIndex === 2 && (
                <Box p={2}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Estado Inicial</InputLabel>
                        <Select
                            value={selectedState1}
                            onChange={(e) => setSelectedState1(e.target.value as DateKeys)}
                        >
                            {stateOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Estado Final</InputLabel>
                        <Select
                            value={selectedState2}
                            onChange={(e) => setSelectedState2(e.target.value as DateKeys)}
                        >
                            {stateOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selectedState1 && selectedState2 && (
                        <Box mt={2}>
                            <h3>Tiempo promedio entre {stateOptions.find(option => option.value === selectedState1)?.label} y {stateOptions.find(option => option.value === selectedState2)?.label}:</h3>
                            <p>{calculateAverageTime(selectedState1, selectedState2)}</p>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ReportsView;
