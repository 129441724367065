import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import store from '../store'; // Import the store to access the state

const handleGenerateExcelOrdersWithInvoices = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Órdenes');

    // Get the trucks from the state
    const state = store.getState();
    const trucks = state.truckReducer.trucks;

    // Function to get truck username by truck ID
    const getTruckUsername = (truckId) => {
        const truck = trucks.find((truck) => truck._id === truckId);
        return truck ? truck.username : 'Unknown';
    };

    // Configuración de las columnas
    worksheet.columns = [
        { header: 'Order ID', key: 'orderId', width: 20 },
        { header: 'Laudus Order ID', key: 'laudusOrderId', width: 20 },
        { header: 'Invoice ID', key: 'invoiceLaudusId', width: 20 },
        { header: 'Numero Documento', key: 'docNumber', width: 20 },
        { header: 'Nombre', key: 'name', width: 25 },
        { header: 'RUT', key: 'rut', width: 15 },
        { header: 'Monto Neto', key: 'netAmount', width: 20 },
        { header: 'Estado', key: 'status', width: 15 },
        { header: 'Nombre Vendedor', key: 'sellerName', width: 20 },
        { header: 'Camion', key: 'truck', width: 20 },
        { header: 'Comuna', key: 'commune', width: 20 },
        { header: 'Fecha Ingreso Pedido', key: 'orderDate', width: 20 },
        { header: 'Fecha Envio a ERP', key: 'sentToERPDate', width: 20 },
        { header: 'Fecha Asignacion Picker', key: 'pickerAssignDate', width: 20 },
        { header: 'Fecha Termino Picker', key: 'pickerFinishDate', width: 20 },
        { header: 'Fecha Factura', key: 'date', width: 20 },
        { header: 'Fecha Asignacion Camion', key: 'assignedTruckDate', width: 20 },
        { header: 'Fecha Cambio de Estado', key: 'changedStatusDate', width: 20 },
    ];

    // Agrega filas al Excel
    data.forEach((row) => {
        worksheet.addRow({
            orderId: row.orderId,
            laudusOrderId: row.laudusOrderId,
            invoiceLaudusId: row.invoiceLaudusId,
            docNumber: row.docNumber,
            name: row.name,
            rut: row.rut,
            netAmount: row.netAmount,
            status: row.status,
            sellerName: row.sellerName,
            truck: getTruckUsername(row.truck),
            commune: row.commune,
            orderDate: row.orderId ? new Date(row.orderId) : null,
            sentToERPDate: row.sentToERPDate ? new Date(row.sentToERPDate) : null,
            pickerAssignDate: row.pickerAssignDate ? new Date(row.pickerAssignDate) : null,
            pickerFinishDate: row.pickerFinishDate ? new Date(row.pickerFinishDate) : null,
            date: row.date ? new Date(row.date) : null,
            assignedTruckDate: row.assignedTruckDate ? new Date(row.assignedTruckDate) : null,
            changedStatusDate: row.changedStatusDate ? new Date(row.changedStatusDate) : null,
        });
    });

    // Formatear las columnas de fecha como fecha
    const dateColumns = ['orderDate', 'sentToERPDate', 'pickerAssignDate', 'pickerFinishDate', 'date', 'assignedTruckDate', 'changedStatusDate'];
    dateColumns.forEach((key) => {
        worksheet.getColumn(key).eachCell((cell) => {
            if (cell.value) {
                cell.numFmt = 'dd/mm/yyyy hh:mm:ss';
            }
        });
    });

    // Generar y guardar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Órdenes.xlsx');
};

export default handleGenerateExcelOrdersWithInvoices;
