import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import Layout from "./components/Layout";
import { Routes, LogisticRoutes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/views/Login/Login";
import "./App.css";
import Home from "./components/views/Home/Home";
import LogisticView from "./components/views/Logistics/LogisticView";
import TrucksView from "./components/views/Trucks/TrucksView";
import PendingOrdersView from "./components/views/PendingOrders/PendingOrdersView";
import DispatchRoutesView from "./components/views/DispatchRoutes/DispatchRoutesView";
import ReportsView from "./components/views/Reports/ReportsView";

function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#FFF",
      },
      primary: {
        main: "#ED6E11",
      },
      secondary: {
        main: "#BF549A",
      },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      initLogin().finally(() => setLoading(false));
    };
    init();
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          {loading ? (
            <div className="h-full flex justify-center items-center">
              <div className="animate-spin">
                <Icon size={6} path={mdiCircleSlice1}></Icon>
              </div>
            </div>
          ) : (
            <AppRoutes>
              <Route
                path={Routes.Home}
                element={<ProtectedRoute element={<Home />}></ProtectedRoute>}
              ></Route>
              <Route
                path={LogisticRoutes.Logistics}
                element={<ProtectedRoute element={<LogisticView />}></ProtectedRoute>}
              ></Route>
              <Route
                path={LogisticRoutes.Trucks}
                element={<ProtectedRoute element={<TrucksView />}></ProtectedRoute>}
              ></Route>
              <Route
                path={LogisticRoutes.PendingOrders}
                element={<ProtectedRoute element={<PendingOrdersView />}></ProtectedRoute>}
              ></Route>
              <Route
                path={LogisticRoutes.DispatchRoutes}
                element={<ProtectedRoute element={<DispatchRoutesView />}></ProtectedRoute>}
              ></Route>
              <Route
                path={LogisticRoutes.Reports}
                element={<ProtectedRoute element={<ReportsView />}></ProtectedRoute>}
              />
              <Route path={Routes.Login} element={<Login />}></Route>
            </AppRoutes>
          )}
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;